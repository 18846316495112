@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

